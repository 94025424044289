<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item>发行版管理</el-breadcrumb-item>
                <el-breadcrumb-item>版本管理</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
                <el-button v-if="params && !selector" type="primary" @click="openImport">本地文件导入</el-button>
                <el-button v-if="params && !selector" type="primary" @click="openOssImport">OSS路径导入</el-button>
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label"/>
                        </template>
                    </template>
                    <el-table-column min-width="200" label="操作">
                        <template slot-scope="scope">
                            <template v-if="!selector">
                                <el-button type="text" @click="openDkdms(scope.row.id)">
                                    母密钥 <i class="el-icon-bank-card"></i>
                                </el-button>
                                <template v-if="editOptions.open">
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="editHandle(scope.row.id)">
                                        修改 <i class="el-icon-edit"></i>
                                    </el-button>
                                </template>
                                <template v-if="detailOptions.open">
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="openDetail(scope.row.id)">
                                        详细 <i class="el-icon-document"></i>
                                    </el-button>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                                <el-button v-if="scope.row.status === 9" type="text" @click="enableHandle(scope.row.id)">
                                    启用 <i class="el-icon-open"></i>
                                </el-button>
                                <el-button v-if="scope.row.status === 8" type="text" @click="disableHandle(scope.row.id)">
                                    停用 <i class="el-icon-turn-off"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="g_notImplemented">
                                    上传 <i class="el-icon-upload"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="g_notImplemented">
                                    下载 <i class="el-icon-download"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="g_notImplemented">
                                    cdn加速 <i class="el-icon-coin"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="g_notImplemented">
                                    验签 <i class="el-icon-s-goods"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="openUrls(scope.row.id)">
                                    地址列表 <i class="el-icon-link"></i>
                                </el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="selectHandle(scope.row)">
                                    选择 <i class="el-icon-check"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <!--popup search-->
            <el-dialog title="母密钥" :visible.sync="dkdmsVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu1_Dkdm :params="searchOptions.searchParams"></Menu1_Dkdm>
            </el-dialog>
            <el-dialog :title="editOptions.id?'修改数据':'添加数据'" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
                <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
            </el-dialog>
            <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
                <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
            </el-dialog>
            <el-dialog title="文件导入影片版本" :visible.sync="importFormVisible" append-to-body destroy-on-close center>
                <SingleUpload :url="importUrl+agreementId" :exts="['json']" @done="importSuccess" @change="showMessage">
                    <template v-slot:message>
                        {{message}}
                    </template>
                </SingleUpload>
            </el-dialog>
            <el-dialog :title="'下载地址列表[' + editForm.versionName + ']'" :visible.sync="urlsVisible" center width="90%" :lock-scroll="false" append-to-body :before-close="handleCloseSearchForm">
                <el-form :model="editForm" ref="editForm" label-width="100px" :inline="true">
                    <el-form-item
                            v-for="(url, index) in editForm.urls"
                            :label="'下载地址' + (index+1)"
                            :key="index"
                            :prop="'urls.' + index + '.value'"
                            :rules="[
                                {required: true, type: 'url', message: '下载地址不是一个有效链接', trigger: 'blur'},
                                {min: 10, message: '不能少于10位', trigger: 'blur'}
                            ]"
                    >
                        <el-input v-model="url.value" style="width: 270px"></el-input>
                        <el-button @click.prevent="removeUrl(url)">删除</el-button>
                    </el-form-item>
                    <el-form-item style="display: block">
                        <el-button type="primary" @click="submitForm('editForm')">提交</el-button>
                        <SingleFileLoader style="display: inline-block;margin:0 10px;" @done="loadFile">加载文件</SingleFileLoader>
                        <el-button @click="addUrl">新增下载地址</el-button>
                        <el-button @click="resetEditForm">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
            <el-dialog title="OSS路径导入影片版本" :visible.sync="ossImportVisible" center width="70%" append-to-body :before-close="closeOssImport">
              <el-form ref="ossform" label-width="80px">
                <el-form-item label="OSS路径">
                  <el-input v-model="ossPath" placeholder="oss://..."></el-input>
                </el-form-item>
                <el-form-item label="版本信息">
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="movieinfoJson" :disabled="true" ></el-input>
                </el-form-item>
                <el-form-item label="地址信息">
                  <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="urlsJson" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="loadOssInfoHandle">加载信息</el-button>
                  <el-button type="primary" @click="submitOssInfoHandle">保存</el-button>
                  <el-button type="primary" @click="updateOssInfoHandle">更新地址</el-button>
                  <el-button @click="closeOssImport">取消</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import Menu1_Dkdm from "./Menu1_Dkdm";
    import EditForm from "../common/EditForm";
    import DetailPanel from "../common/DetailPanel";
    import SingleUpload from "../common/SingleUpload";
    import SingleFileLoader from "../common/SingleFileLoader";
    import {Search_Type} from "../../constants";
    const prefix = '/film/version';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id) => `${prefix}/one/${id}`
    const importUrl = `${prefix}/import/`;
    const getDetailUrl = (id) => `${prefix}/detail/${id}`
    const postUrl = `${prefix}/save`;
    const putUrl = (id) => `${prefix}/update/${id}`
    const enableUrl = (id)=>`${prefix}/enable/${id}`
    const disableUrl = (id)=>`${prefix}/disable/${id}`
    const importOssUrl = (id)=>`${prefix}/ossimport/${id}`
    const updateOssUrl = ()=>`${prefix}/ossupdate`
    export default {
        name: 'Menu1_Version',
        components: {SearchForm,Menu1_Dkdm,EditForm,DetailPanel,SingleUpload,SingleFileLoader},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                    postUrl: postUrl,
                    getOneUrl: getOneUrl,
                    putUrl: putUrl,
                },
                detailOptions: {
                    getDetailUrl : getDetailUrl,
                },
                editFormVisible: false,
                dkdmsVisible: false,
                detailPanelVisible: false,
                urlsVisible: false,
                importFormVisible: false,
                importUrl: importUrl,
                agreementId:'',
                message:'',
                editForm: {},
                ossImportVisible: false,
                ossPath: '',
                movieinfoJson: '',
                urlsJson: '',
                ossResult: false,
            }
        },
        props:['params','selector'],
        created() {
            this.g_setupMetadataConfig(this, this.$options.name)
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.loadDynamicSelectData()
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            loadDynamicSelectData(){
                let needLoad = true;
                //加载动态键值对
                this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl || item.props) return;
                    needLoad = false
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter num'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                        }catch (e) {
                            console.log(e)
                        }
                        this.getTableData()
                    })
                })
                if(needLoad) this.getTableData()
            },
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            closeMenu(done){
                this.searchOptions.searchParams = []
                done();
            },
            openImport(){
                if(this.params && this.params.length > 0){
                    this.params.forEach(param=>{
                        if(param.name && param.name==='agreementId'){
                            this.agreementId = param.value;
                        }
                    })
                }
                if(this.agreementId){
                    this.importFormVisible = true;
                }else{
                    this.$message.error("导入功能需从影片管理->版本管理中打开");
                }
            },
            openOssImport(){
                if(this.params && this.params.length > 0){
                  this.params.forEach(param=>{
                    if(param.name && param.name==='agreementId'){
                      this.agreementId = param.value;
                    }
                  })
                }
                if(this.agreementId){
                  this.ossImportVisible = true;
                }else{
                  this.$message.error("导入功能需从影片管理->版本管理中打开");
                }
            },
            importSuccess(){
                this.importFormVisible = false;
                this.getTableData();
            },
            showMessage(file){
                if (file){
                    let reader = new FileReader();
                    reader.onload = e => {
                        const result = JSON.parse(e.target.result);
                        if (result && result.title) {
                            this.message = "title:" + result.title;
                        } else {
                            this.message = "未能获取title属性";
                        }
                    }
                    reader.readAsText(file.raw);
                }else{
                    this.message = '';
                }
            },
            handleCloseSearchForm(done){
                this.searchOptions.searchParams = []
                done();
            },
            openDkdms(id){
                this.dkdmsVisible = true;
                this.searchOptions.searchParams = [{name:'versionId',value:id}];
            },
            openDetail(id){
                this.detailOptions.id = id;
                //this.detailOptions.initData = row
                this.detailPanelVisible = true
            },
            editHandle(id){
                this.editOptions.id = id
                this.editFormVisible = true
            },
            closeEditForm(result){
                this.editOptions.id = null
                this.editFormVisible = false
                if(result){
                    this.getTableData()
                }
            },
            closeDetailPanel(){
                this.detailOptions.id = null
                this.detailPanelVisible = false
            },
            closeOssImport(){
                this.ossPath = ''
                this.movieinfoJson = ''
                this.urlsJson = ''
                this.ossImportVisible = false
                if(this.ossResult){
                    this.ossResult = false
                    this.getTableData()
                }
            },
            selectHandle(row){
                this.$emit('onselect', row)
            },
            openUrls(id){
                this.$axios.get(getOneUrl(id)).then(res=>{
                    this.editForm = {
                        id: res.data.id,
                        versionName: res.data.versionName,
                        urls: [{value:""}],
                    }
                    if(res.data.urlpath && res.data.urlpath.length > 0){
                        const urls = res.data.urlpath.split(",");
                        this.editForm.urls = urls.map(url=>({value:url}));
                    }
                    this.urlsVisible = true
                });
            },
            removeUrl(url) {
                var index = this.editForm.urls.indexOf(url)
                if (index !== -1) {
                    this.editForm.urls.splice(index, 1)
                }
            },
            addUrl() {
                if(this.editForm.urls.length < 10){
                    this.editForm.urls.push({
                        value: '',
                        key: Date.now()});
                }else{
                    this.$message.error("最多增加10个地址")
                }
            },
            loadFile(file){
                let reader = new FileReader();
                reader.onload = e => {
                    try {
                        const result = JSON.parse(e.target.result);
                        let checkArray = false;
                        if(Array.isArray(result)){
                            if(result.filter(v=> typeof v === 'string').length === result.length){
                                checkArray = true;
                            }
                        }
                        if(!checkArray){
                            this.$message.error("文件内容不符合要求,样例['url1','url2']")
                            return false
                        }
                        this.editForm.urls = result.filter(v=>v.trim().length>0).map(url=>({value:url}));
                        this.$message.success(`加载成功,共找到${this.editForm.urls.length}个字符串`)
                    }catch (e) {
                        this.$message.error("文件解析失败")
                    }
                }
                reader.readAsText(file.raw);
            },
            resetEditForm() {
                const {id, versionName} = this.editForm;
                this.editForm = {id, versionName , urls: [{value:""}]};
            },
            submitForm(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.editForm.id){
                            this.$axios.put(putUrl(this.editForm.id),{
                                urls:  this.editForm.urls.map(url=>url.value)
                            }).then(()=>{
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose:() => {
                                    }
                                });
                                this.urlsVisible = false;
                            });
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            loadOssInfoHandle(){
                if(!this.ossPath || !this.ossPath.trim()){
                    this.$message.error("不是一个有效的oss路径")
                    return false;
                }
                const movieinfoPath = process.env.VUE_APP_OSSPATH_MOVIEINFO_PREFIX + this.ossPath;
                const urlsPath = process.env.VUE_APP_OSSPATH_URLS_PREFIX + this.ossPath;
                this.$axios.get(movieinfoPath, {
                    responseType: 'blob', // important
                    timeout: 60000,
                }).then((response) => {
                    let reader = new FileReader();
                    reader.onload = e => {
                      try {
                        this.movieinfoJson = e.target.result;
                      }catch (e) {
                        this.$message.error("版本信息获取失败")
                      }
                    }
                    reader.readAsText(response.data);
                });
                this.$axios.get(urlsPath, {
                    responseType: 'blob', // important
                    timeout: 60000,
                }).then((response) => {
                    let reader = new FileReader();
                    reader.onload = e => {
                      try {
                        this.urlsJson = e.target.result;
                      }catch (e) {
                        this.$message.error("地址信息获取失败")
                      }
                    }
                    reader.readAsText(response.data);
                });
            },
            submitOssInfoHandle(){
                if(!this.movieinfoJson || !this.urlsJson){
                    this.$message.error("请先填写OSS路径并加载信息")
                    return false
                }
                let urlsArray = null
                try {
                    urlsArray = JSON.parse(this.urlsJson)
                }catch (e){
                    this.$message.error("无效的地址信息")
                    return false
                }
                if(!Array.isArray(urlsArray)){
                    this.$message.error("无效的地址信息")
                    return false
                }
                this.$axios.post(importOssUrl(this.agreementId),{
                    movieinfo: this.movieinfoJson,
                    urls: urlsArray
                }).then(()=>{
                    this.ossResult = true
                    this.$message.success("恭喜你，操作成功")
                    this.ossPath = ''
                    this.movieinfoJson = ''
                    this.urlsJson = ''
                })
            },
            updateOssInfoHandle(){
              if(!this.movieinfoJson || !this.urlsJson){
                this.$message.error("请先填写OSS路径并加载信息")
                return false
              }
              let urlsArray = null
              try {
                urlsArray = JSON.parse(this.urlsJson)
              }catch (e){
                this.$message.error("无效的地址信息")
                return false
              }
              if(!Array.isArray(urlsArray)){
                this.$message.error("无效的地址信息")
                return false
              }
              this.$axios.put(updateOssUrl(),{
                movieinfo: this.movieinfoJson,
                urls: urlsArray
              }).then(()=>{
                this.$message.success("地址更新成功")
                this.ossPath = ''
                this.movieinfoJson = ''
                this.urlsJson = ''
              })
            },
        }
    }
</script>

<style scoped>

</style>